import Card from "./Card"
import React from "react"

const EventsCard = function EventsCard({ event, className }) {
  return (
    <a
      href={event.externalLink}
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      <Card
        title={event.title}
        subtitle={event.formattedDateString}
        description={<p>{event.excerpt}</p>}
        tags={event.tags}
        image={{
          imgAlt: event.imageAltText,
          fluidImg: event.image,
        }}
      />
    </a>
  )
}

export default EventsCard
