import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import styles from "./Card.module.scss"

Card.propTypes = {
  title: PropTypes.node.isRequired,
  image: PropTypes.shape({
    fluidImg: PropTypes.object,
    imgAlt: PropTypes.string,
  }),
  top: PropTypes.node,
  subtitle: PropTypes.node,
  description: PropTypes.node,
  bottomRight: PropTypes.node,
  tags: PropTypes.arrayOf(PropTypes.node),
}

function Card({ image, top, title, subtitle, description, bottomRight, tags }) {
  return (
    <div className={`select-none w-full h-full ${styles.card}`}>
      <div className={`${styles.context}`}>
        <div className="flex flex-col h-full">
          {image != null && image.fluidImg != null && (
            <div className={styles.imageContainer}>
              <Img
                className={styles.image}
                fluid={image.fluidImg}
                alt={image.imgAlt}
              />
            </div>
          )}
          <div className={`flex-grow mb-4 ${styles.block}`}>
            {top != null && <div className={styles.author}>{top}</div>}
            <div className={styles.title}>{title}</div>
            {subtitle != null && <div className={styles.date}>{subtitle}</div>}
            {description != null && <div className="">{description}</div>}
          </div>
          <div className={`flex ${styles.bottomContainer}`}>
            <div
              className={`flex-grow flex items-center flex-wrap gap-y-1 ${styles.tags}`}
            >
              {tags != null &&
                tags.length !== 0 &&
                tags.map((tag, idx) => (
                  <div key={idx} className={styles.tag}>
                    {tag}
                  </div>
                ))}
            </div>
            {bottomRight != null && (
              <div className={`self-end ${styles.number}`}>{bottomRight}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
